import { FC, useState } from 'react';

import { Box } from '@mui/system';

import { DefaultModalVariation } from '../types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { ItemPosition, TextColor } from '@lib/slice-machine/types';
import { SxStyles } from '@lib/theme/sxTheme';
import CloseIcon from '@mui/icons-material/Close';
import { hasRichTextContent } from '@lib/slice-machine/richText';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';
import { mapModalWidthToBreakpoint } from '@lib/slice-machine/blockWidth';
import { SliceLayout } from '@ui/slice-machine/SliceLayout';

interface Props {
  slice: DefaultModalVariation;
}

export const DefaultModal: FC<Props> = ({ slice }) => {
  const {
    title,
    content,
    modalWidth,
    ctaText,
    ctaBackgroundColor,
    ctaTextColor,
    ctaPosition,
    paddingTop,
    paddingBottom,
    backgroundColor,
    width,
  } = slice.primary;
  const dynamicStyles = styles({
    ctaTextColor,
    ctaPosition,
  });
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SliceLayout
      backgroundColor={backgroundColor}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      width={width}
    >
      <Box sx={dynamicStyles.getValue('content')}>
        {ctaText && (
          <Button
            variant="contained"
            color={ctaBackgroundColor ?? undefined}
            sx={dynamicStyles.getValue('button')}
            onClick={handleOpen}
          >
            {ctaText}
          </Button>
        )}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={mapModalWidthToBreakpoint(modalWidth)}
      >
        <DialogTitle sx={dynamicStyles.getValue('dialogTitle')}>
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={dynamicStyles.getValue('dialogClose')}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={dynamicStyles.getValue('dialogContent')}>
          {hasRichTextContent(content) && <CustomRichText render={content} />}
        </DialogContent>
      </Dialog>
    </SliceLayout>
  );
};

interface StyleProps {
  ctaTextColor: TextColor | null;
  ctaPosition: ItemPosition | null;
}

const styles = ({ ctaPosition, ctaTextColor }: StyleProps) =>
  new SxStyles({
    content: {
      textAlign: ctaPosition,
    },
    button: {
      fontSize: 18,
      color: ctaTextColor ?? undefined,
      borderRadius: 5,
    },
    dialogClose: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: 'text.primary',
    },
    dialogTitle: {
      pr: 8,
      fontSize: 30,
    },
    dialogContent: {
      p: (theme) => theme.spacing(0, 3),
      m: (theme) => theme.spacing(2, 0, 3),

      '& p': {
        mt: 0,
        mb: 2,
      },
    },
  });
